@use "Variables";
.aboutGameReviewz {
  width: 60%;
  height: 300px;
  margin-top: 20px;
  background-color: #0d0d0d;
  color: white;
  font-size: Variables.$xxl-text;
  font-family: Baskerville, Baskerville Old Face, Hoefler Text, Garamond,
    Times New Roman, serif;
  text-align: center;
  p {
    font-size: Variables.$xl-text;
  }
}

.navban {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    color: white;
    font-size: 3.2em;
    font-weight: 700;
    text-decoration: none;
    transition: 0.5s;
    padding: 10px;
  }
  span {
    color: aqua;
  }
  ul {
    display: flex;
    gap: 40px;
    padding: 10px;
    li {
      list-style: none;
      a {
        text-decoration: none;
        font-weight: 500;
        font-size: 2em;
        color: white;
        transition: 0.5s;
      }
      a:hover {
        color: aqua;
      }
    }
  }
}

.navbanner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s;
  margin-bottom: 100px;
  padding: 100px;
}

.sidelinks {
  padding: 5%;
  ul {
    padding: 0;
    gap: 20px;
    display: flex;
  }
  li {
    list-style: none;
    a {
      text-decoration: none;
      font-size: 3rem;
    }
    a:hover {
      color: aqua;
    }
  }
}
.tybottombackground {
  background-color: black;
}

.tybackground {
  background-color: black;
  box-sizing: border-box;
  height: 100vh;
  max-width: 100%;
  overflow: hidden;
}

.typictures {
  .moon {
    position: absolute;
    right: 150px;
    top: 60px;
    user-drag: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    animation: animateMoon 8s ease-in-out infinite;
  }
  .tyMe {
    position: absolute;
    max-width: 400px;
    top: 25%;
    right: 25%;
    // user-drag: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    animation: animateMoon 8s ease-in-out infinite;
    // box-shadow: inset 0 0 40px 16px;
  }
}

.tyRectangle {
  height: 400px;
  width: 13%;
  background-color: #0d0d0d;
  .tyRectangleText {
    color: white;
    font-size: Variables.$lg-text;
    font-weight: bold;
    text-align: center;
    a:visited {
      color: aqua;
    }
    a:link {
      color: aqua;
    }
  }
  svg {
    font-size: Variables.$xxl-text;
    color: white;
  }
}

.tyRectangleContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

@keyframes animateMoon {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}

.typedwrap {
  color: white;
  padding: 5%;
  font-size: Variables.$xl-text;
  span {
    font-size: Variables.$lg-text;
    width: 100%;
    color: aqua;
  }
}

@media only screen and (max-width: 700px) {
  .tyRectangle {
    height: 300px;
    .tyRectangleText {
      font-size: Variables.$md-text;
    }
  }
}
@media only screen and (max-width: 500px) {
  .aboutGameReviewz {
    width: 100%;
  }
  .tybackground {
    height: 70vh;
  }
  .typedwrap {
    position: absolute;
    top: 0;
  }
  .typictures {
    display: flex;
    justify-content: center;
    align-items: center;
    .moon {
      position: absolute;
      top: 25%;
      left: 20%;
      height: 250px;
      width: 250px;
    }
    .tyMe {
      position: absolute;
      top: 25%;
      left: 20%;
      height: 250px;
      width: 250px;
    }
  }
  .tyRectangle {
    min-width: 150px;
  }
  .tyRectangle .tyRectangleText {
    font-size: 1.3rem;
  }
}
@media only screen and (max-width: 800px) {
  .typictures .moon {
    right: 0;
  }
  .typictures .tyMe {
    right: 0;
  }
}
@media only screen and (max-width: 1000px) {
  .aboutGameReviewz {
    p {
      font-size: Variables.$lg-text;
    }
  }
}
@media only screen and (max-width: 1440px) {
  .tyRectangle {
    width: 20%;
  }
}
