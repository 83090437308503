@use "Variables";
$enable-important-utilities: false;

.animate {
  animation: animatezoom 0.6s;
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

body {
  background-color: Variables.$background;
  color: black;
  font-family: Variables.$siteFont;
  margin: 0;
}

.carousel-root {
  cursor: pointer;
}

.containercenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carouselcomponent {
  padding: 2%;
}
.darkmode {
  background-color: black;
  color: white !important;

  nav {
    color: white;
  }
}
.displayflex {
  display: flex;
}
.dropdown {
  background-color: gray;
  margin-top: 10px;
  max-height: 0px;
  overflow: hidden;
  position: absolute;
  right: 2%;
  top: 5%;
  transition: max-height 0.5s;
  width: 200px;
  z-index: 5;
  .profiletab {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    padding: 10px;

    div {
      font-size: Variables.$md-text;
    }
  }
  hr {
    width: 98%;
  }
  .dropdowntab {
    cursor: pointer;
    display: flex;
    font-size: Variables.$md-text;
    font-weight: 300;
    justify-content: space-between;
    padding: 5%;

    div {
      display: flex;
      gap: 5px;

      svg {
        color: white;
      }
    }
    .arrowdropdown {
      transition: transform 0.5s;
    }
  }
}
.dropdown.openmenu {
  max-height: 210px;
}
.dropdowntab:hover div {
  font-weight: bold;
}
.dropdowntab:hover .arrowdropdown {
  transform: translateX(5px);
}
.displayflex {
  display: flex;
}
.dropdown {
  background-color: gray;
  margin-top: 10px;
  max-height: 0px;
  overflow: hidden;
  position: absolute;
  right: 2%;
  top: 5%;
  transition: max-height 0.5s;
  width: 200px;
  z-index: 5;

  .profiletab {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    padding: 10px;

    div {
      font-size: Variables.$md-text;
    }
  }
  hr {
    width: 98%;
  }
  .dropdowntab {
    cursor: pointer;
    display: flex;
    font-size: Variables.$md-text;
    font-weight: 300;
    justify-content: space-between;
    padding: 5%;

    div {
      display: flex;
      gap: 5px;

      svg {
        color: white;
      }
    }
    .arrowdropdown {
      transition: transform 0.5s;
    }
  }
}
.dropdown.openmenu {
  max-height: 210px;
}
.dropdowntab:hover div {
  font-weight: bold;
}
.dropdowntab:hover .arrowdropdown {
  transform: translateX(5px);
}

.displayflex {
  display: flex;
}
.dropdown {
  position: absolute;
  background-color: gray;
  top: 5%;
  right: 2%;
  z-index: 5;
  margin-top: 10px;
  width: 200px;
  max-height: 0px;
  transition: max-height 0.5s;
  overflow: hidden;
  .profiletab {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 10px;
    div {
      font-size: Variables.$md-text;
    }
  }
  hr {
    width: 98%;
  }
  .dropdowntab {
    display: flex;
    justify-content: space-between;
    padding: 5%;
    font-size: Variables.$md-text;
    font-weight: 300;
    cursor: pointer;
    div {
      display: flex;
      gap: 5px;
      svg {
        color: white;
      }
    }
    .arrowdropdown {
      transition: transform 0.5s;
    }
  }
}
.dropdown.openmenu {
  max-height: 210px;
}
.dropdowntab:hover div {
  font-weight: bold;
}
.dropdowntab:hover .arrowdropdown {
  transform: translateX(5px);
}
.imgstyle {
  height: 360px;
  object-fit: cover;
  // object-position: 20% 0;

  //
}
.footer {
  align-items: center;
  background-color: black;
  color: white;
  display: flex;
  font-size: Variables.$md-text;
  height: 200px;
  justify-content: space-between;
  padding: 0 2.5%;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 2.5%;
    ul {
      margin: 0;
      padding: 0;
      text-decoration: none;
      display: flex;
      column-gap: 15px;
      row-gap: 10px;
      flex-wrap: wrap;
      a {
        color: white;
        list-style: none;
        text-decoration: none;
        font-size: 14px;
        line-height: 1;
        li {
          margin: auto;
        }
      }
    }
    h1 {
      font-weight: bold;
      font-size: 20px;
      line-height: 2;
      span {
        color: red;
      }
    }
    p {
      color: gray;
      font-size: Variables.$sm-text;
      margin-top: 0;
    }
  }
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  //padding: 0.375rem 0.75rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control.is-invalid {
  border-color: #dc3545;
  // padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.357em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-invalid:focus {
  border-color: #dc3545;
  // box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.formGroup {
  margin-bottom: 1.6rem;
}

html {
  font-size: 62.5%; //Sets the Font Size of the site to 10px instead of 16px. So now we can use rem to set px size. Ex: 15 px == 1.5rem.
}

.loading {
  animation: loading 2s infinite linear;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

#logo {
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

#logoShort {
  display: none;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}

.loginContainer {
  display: flex;
  min-width: 150px;
  height: 100%;
  justify-content: space-between;
  margin: auto 10px;
}
.loginButton {
  background-color: #3285ff;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 10px;
}
.loginButton.signup {
  background-color: black;
  color: white;
}
.loginButton:hover {
  color: red;
  font-weight: bold;
}

nav {
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  width: 100%;
  background-color: white;
  span {
    color: red;
  }
}

nav a.active {
  color: rgb(230, 13, 13) !important;
  font-weight: bold;
}
.navlink {
  text-decoration: none;
  color: black;
}
.navItems {
  display: flex;
  height: 100%;
  width: 100%;
  margin: auto 10px;
  padding: 0;
  align-items: center;

  li {
    list-style: none;
    margin: auto 10px;

    .navitem {
      font-size: 1.5rem;
      text-decoration: none;
    }
  }
}
.navitem::after {
}
.navitem:hover {
  // color: red;
  // font-weight: bold;
}
nav a:visited {
  color: black;
  text-decoration: none;
}
.notification {
  animation: notiAnimation 1 1.5s;
  background-color: lightcoral;
  position: absolute;
  width: 300px;
  right: 10px;
  top: 0;
}
@media only screen and (max-width: 810px) {
  nav {
    font-size: 1rem;
  }
  .navItems {
    margin: 0;
    li {
      margin: auto 5px;
      .navitem {
        font-size: 1rem;
      }
    }
  }
  .loginButton {
    font-size: 1rem;
    padding: 6px;
  }
  .loginContainer {
    min-width: 90px;
  }
}
@media only screen and (max-width: 500px) {
  #logo {
    display: none;
  }
  #logoShort {
    display: inline-block;
  }
  .loginContainer {
    margin: auto 5px;
  }
}
@media only screen and (max-width: 410px) {
  .loginContainer {
    margin: auto;
    width: auto;
  }
  .navItems {
    width: auto;
    li {
      margin: auto 3px;
    }
  }
}
@keyframes notiAnimation {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
}
.personcircle {
  font-size: 2rem;
}
.profileside {
  cursor: pointer;
  display: flex;
  gap: 2px;
}
.searchinput {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  outline: none;
}

.successNoti {
  background-color: rgb(12, 191, 12);
}

.xButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
