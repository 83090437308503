.photo{
  background: url("https://e0.pxfuel.com/wallpapers/527/1001/desktop-wallpaper-sunset-top-best-sunset-background-relaxing-sunset.jpg") fixed;
  background-size: cover;
  background-position: center;
}
.johnNav{
  align-items: center;
  display: flex;
  font-size: 1.75rem;
  width: 100%;
  height: 4em;
  background-color: transparent;
}
.johnNavMobile{
  align-items: center;
  font-size: 1.75rem;
  width: 100%;
  display: none;
  background-color: transparent;
}
.johnNavItems{
  display: flex;
  height: 100%;
  width: 100%;
  margin: auto 10px;
  padding: 0;
  align-items: center;
  justify-content: space-between;
  ul{
    display: flex;
    margin: auto 0 auto auto;
    padding: 0;
  }
  li{
    list-style-type: none;
    margin: auto;
    padding: 0 10px;
    border-right: #ffda1a 0.1em solid;
    filter: drop-shadow(2px 2px black);
    a{
      color: #ffda1a;
      text-decoration: none;
      font-size: 1.2em;
      font-weight: bolder;
    }
  }
  .lastLi{
    border-right: none;
    padding-right: 0;
  }
  .johnName{
    font-size: 2em;
    font-weight: bold;
    span {
      background: linear-gradient(#ffdd00, #b95260);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      filter: drop-shadow(2px 2px black);
    }
  }
}
.navContainer{
  display: flex;
  margin: auto 0 auto auto;
  justify-content: space-between;
}

.container {
  display: flex;
  height: auto;
}
.profileContainer{
  width: 100%;
  padding: 0 10px;
  margin: auto;
  align-items: center;
  display: flex;
}
.profileIntro{
  font-size: 16px;
  width: 70%;
  margin: 66.2px auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  color: white;
}
.profilePhoto{
  background-color: white;
  margin: 7em auto;
  width: 25em;
  border-radius: 50%;
  height: 25em;
  img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.greetingPara{
  font-size: 1.2em;
  margin: 10px 0;
  line-height: 1.5;
  text-align: left;
  text-shadow: 1.5px 1.5px black;
}
.greeting{
  margin: 0;
  text-align: left;
  text-shadow: 2px 2px black;
}

.defaultContainer{
  display: flex;
  margin: 20px 5%;
  flex-direction: column;
  .logos {
    pointer-events: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: 2rem;
    margin: auto;
    width: 90%;
    img{
      height: 10vw;
      margin-bottom: 10px;
      min-height: 70px;
      max-height: 100px;
    }
  }
  .skills{
    font-size: 3em;
    margin: 0 auto;
    font-weight: bold;
    color: #541a8b;
    text-shadow: 1px 1px grey;
  }
}

.johnContainer {
  gap: 10px;
  flex-wrap: wrap;
  display: flex;
  margin: 20px 5%;
  justify-content: center;
}

.johnContent{
  height: 300px;
  width: 40%;
  border-color: black;
  border-style: solid;
  border-radius: 15px;
  border-width: 3.5px;
  display: flex;
  .contentContainer{
    margin: 1%;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    .contentIcon{
      font-size: 45px;
      margin-top: 15%;
      filter: drop-shadow(1px 1px black);
      .iconLink{
        color: #ffda1a;
      }
      .iconLink:hover{
        color: #541a8b;
      }
    }
    .contentTitle{
      font-weight: bold;
      text-align: center;
      font-size: 25px;
      margin-top: 0;
    }
    .content{
      font-weight: bold;
      text-align: center;
      font-size: 18px;
      margin: 0 5%;
    }
  }
}
.johnContent:hover{
  border-color: #541a8b;
}

@media only screen and (max-width: 975px) {
  .profileContainer {
    flex-direction: column-reverse;
  }
  .profilePhoto{
    width: 20em;
    height: 20em;
    margin: 5em auto;
  }
  .profileIntro{
    font-size: 14px;
    width: 80%;
    margin: 20px auto;
  }
  .johnNav{
    height: 100%;
    margin-bottom: 20px;
  }
  .johnNavMobile{
    display: none;
  }
  .johnNavItems {
    justify-content: center;
    flex-direction: column;
    ul{
      margin: auto;
    }
    .lastLi{
      padding: 0 10px;
    }
  }
  .defaultContainer{
    .skills{
      font-size: 2em;
    }
  }
  /*
  .navContainer{
    flex-direction: column;
  }
  .navItems{
    justify-content: center;
    flex-direction: column;
    ul{
      margin: auto;
    }
    li{
      filter: none;
      border-right: none;
      padding: 0;
      margin: 2px auto;
      a{
        text-shadow: 2px 2px black;
      }
    }
  }

   */
  .johnName{
    margin: 10px auto;
  }
  .johnContent{
    height: 250px;
  }
}
@media only screen and (max-width: 630px) {
  .johnContent{
    width: 47%;
    .contentContainer{
      .contentIcon{
        margin-top: 20%;
      }
      .contentTitle{
        font-size: 23px;
      }
      .content{
        font-size: 16px;
      }
    }
  }
  .johnContainer{
    margin: 20px 1%;
  }
}
@media only screen and (max-width: 491px) {
  .defaultContainer{
    margin: 20px 0;
  }
  .profilePhoto{
    width: 15em;
    height: 15em;
    margin:2.5em auto;
  }
  .profileIntro{
    font-size: 12px;
    width: 80%;
    margin: 10px auto;
  }
  .johnNavItems{
    li {
      margin: auto;
      padding: 0 5px;
      a{
        font-size: .8em;
      }
    }
    .lastLi{
      padding: 0 5px;
    }
  }
  .johnContainer{
    gap: 5px;
  }
}
