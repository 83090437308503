@font-face {
    font-family: myFontLight;
    src: url("../assets/Oxanium-Light.ttf");
}

@font-face {
    font-family: myFontMedium;
    src: url("../assets/Oxanium-Medium.ttf");
}

@font-face {
    font-family: myFontBold;
    src: url("../assets/Oxanium-Bold.ttf");
}

.paulMobileBreak{
    display: none;
}

.mySkills{
    display: flex;
    height: calc(80%);
    width: 100%;
    margin: auto;
    flex-wrap: wrap;
    img {
        height: 8vw;
        margin: auto;
        min-height: 65px;
    }
}

.navHidden{
    transform: translateY(calc(-1 * var(--nav-height)));
}

.paulAbout{
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 5%;
    font-family: myFontLight;
    p{
        font-size: 1.5vw;
    }
    a{
        color: #18D5FE;
    }
}

.paulContact{
    text-align: center;
    justify-content: center;
    margin-left: 10%;
    margin-bottom: 5%;
    margin-right: 10%;
    p{
        font-family: myFontLight;
        font-size: 1.5vw;
    }
    table{
        margin: auto;
    }
    tr{
        display: block;
    }
    svg{
        font-size: 5vw;
    }
    .paulTableText{
        font-size: 19px;
        vertical-align: middle;
    }
    a{
        text-decoration: none;
        //color: black;
        font-family: myFontlight;
    }
}

.paulContainer{
    button{
        background-color: transparent;
        border: none;
    }
}

.paulExperience{
    margin-left: 10%;
    margin-right: 10%;
    a{
        color: #18D5FE;
    }
    h1{
        font-size: 2vw;
        font-family: myFontBold;
    }
    h2{
        font-size: 1.8vw;
        font-family: myFontBold;
    }
    h3{
        font-size: 1.65vw;
        font-family: myFonyMedium;
    }
    p, li{
        font-size: 1.5vw;
        font-family: myFontLight;
    }
    ul{
        list-style-type: circle;
    }
}

.paulHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 3%;
    background-color: #18D5FE;
    h1{
        font-size: 3vw;
        font-size-adjust: .5;
        color: white;
    }
    p{
        font-size: 2vw;
        font-size-adjust: 0.5;
    }
}

.paulHeaderLeft{
    float: left;
    padding-left: 15%;
}

.paulHeaderPhoto {
    float: none;
    max-width: 100%;
    height: auto;
    margin: 3% 0;
    padding-right: 15%;
    position: relative;
    img {
        position: static;
        transform: none;
        margin: auto;
        display: block;
        width: 400px;
        height: auto;
        border-radius: 50%;
        border: 5px solid black;
        overflow: hidden;
        background-color: whitesmoke;
    }
}

.paulHome{
    background-color:#18D5FE;
    border-bottom: 50px solid black;
    font-family: myFontMedium;
    --nav-height: 65px;
    padding-top: var(--nav-height);
}

.paulLinkedLogos{
    svg{
        font-size: 8em;
    }
    img{
        height: 8em;
    }
    margin: 0 5px;
}

.paulNav{
    text-align: center;
    height: 55px;
    padding: 10px;
    transition: 0.2s;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #3db4cf;
    font-family: myFontBold;
    z-index: 1;
    a{
        text-decoration: none;
        color: black;
        font-size: 2em;
        font-weight: bolder;
    }
    a:hover{
        color: white;
        font-weight:bold;
    }
    .mainLogo{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 1.5vw;
        padding: 1%;
        span{
            color: red;
        }
    }
    .paulLogo{
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.5vw;
        padding: 1%;
    }
    .paulLogoShort{
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 2em;
        padding: 1%;
    }
    ul{
        display: inline-block;
        list-style: none;
        margin: 0;
        padding: 1%;
        text-align: center;
        li{
            display: inline-block;
            margin: 0 10px;
            span{
                color: white;
            }
        }
    }
}

.paulResume{
    text-align: center;
    .downloadButton{
        font-family: myFontBold;
        font-size: 2vw;
        color: white;
        background-color:  black;
        border-radius: 8px;
        display: block;
        margin: auto;
        width: fit-content;
        box-shadow: 10px 5px 5px #3db4cf;
    }
    a{
        text-decoration: none;
        color: white;
        padding: 5px;
    }
}

.paulTitles{
    font-size: 2vw;
    color: #18D5FE;
    font-family: myFontBold;
    text-align: center;
    padding-top: 40px;
}

.paulTechnologies{
    text-align: center;
}

@media (max-width: 1090px) {
    .paulContainer{
        p{
            font-size: 19px;
            margin: 0;
        }
    }
    .paulExperience{
        h1{
            font-size: 23px;
        }
        h2{
            font-size: 22px;
        }
        h3{
            font-size: 21px;
        }
        li{
            font-size: 19px;
        }
        ul{
            margin: 10px;
        }
    }
    .paulHeaderLeft{
        padding-left: 5%;
        h1{
            font-size: 35px;
        }
        p{
            font-size: 19px;
        }
    }
    .paulHeaderPhoto{
        padding-right: 5%;
    }
    .paulNav {
        //position: relative;
        .paulLogo{
            font-size: 2em;
        }
        .mainLogo{
            font-size: 1.8em;
        }
    }
    .paulResume{
        .downloadButton{
            font-size: 20px;
        }
    }
    .paulTitles{
        font-size: 27px;
    }
}
@media (max-width: 767px) {
    .paulMobileBreak{
        display: block;
    }
    .paulHeader {
        flex-wrap: wrap;
        margin-top: 5%;
        justify-content: center;
        align-items: center;
        padding: 2%;
    }

    .paulHeaderLeft {
        float: none;
        margin: 0;
        text-align: center;
        h1{
            font-size: 35px;
        }
        p{
            font-size: 19px;
        }
    }

    .paulHeaderPhoto {
        margin-top: 10%;
        padding-left: 5%;
        img {
            width: 300px;
        }
    }

    .paulNav {
        //position: relative;
        .paulLogo{
            font-size: 2em;
        }
        .mainLogo{
            font-size: 1.8em;
        }
    }

    .mainLogo,
    .paulLogo {
        padding: 2%;
        position: static;
        margin: auto;
    }

    ul {
        padding: 2% 0;
    }

    li {
        display: list-item;
        margin: 10px 0;
    }

    .paulAbout {
        margin: 5%;
    }

    .paulLinkedLogos {
        margin: 5px;
        svg {
            font-size: 8vw;
        }
        img{
            height: 8vw;
        }
    }

    .paulTechnologies {
        padding: 5%;
    }
}