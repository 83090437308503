@use "Variables";

.articleboxcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.articleboxcontainer:hover {
  background-color: lightgray;
}

.articleBox {
  display: flex;
  margin: 15px auto;
  width: 90%;
  a {
    margin: auto 0;
    height: 200px;
    aspect-ratio: 16/9;
  }
  h1 {
    margin-block-start: 0;
    font-size: Variables.$xl-text;
  }
}

.articleStyle {
  cursor: pointer;
  object-fit: cover;
  object-position: 50% 50%;
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  // max-width: 100%;
}

.articleText {
  font-size: Variables.$lg-text;
  font-style: italic;
}

.bottomArticleBox {
  cursor: pointer;
}

#bottom {
  font-weight: bold;
  font-size: Variables.$md-text;
  font-style: italic;
  margin: 2px auto;
  svg {
    font-size: Variables.$md-text;
  }
}

.gbigText {
  padding-left: 2%;
  margin-block-end: 0;
}
.gline {
  border-width: 1px;
  width: 90%;
  background-color: red;
}

.rectangle {
  border-color: black;
  border-width: thick;
  border: solid;
  height: 300px;
  width: 100%;
}

.selectOptions {
  margin: auto 10px;
}

.sortBox {
  align-items: center;
  display: flex;
}

.topContainer {
  display: flex;
  height: 300px;
}

#topStyle {
  border-right: none;
  border-width: "thick";
}

.vl {
  border-left: 2px solid black;
  margin: 0 20px;
}

@media only screen and (max-width: 700px) {
  .articleboxcontainer {
    border-color: black;
    border-width: 10px;
  }
  .articleBox {
    flex-direction: column;
    a {
      margin: auto;
      width: 100%;
      height: auto;
    }
  }
  .vl {
    border-top: 2px solid black;
    margin: 10px 0;
  }
  .gline {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .articleboxcontainer {
    h1 {
      font-size: 2rem;
    }
  }
  .articleText {
    font-size: 1.5rem;
  }
  #bottom {
    font-size: 1.4rem;
  }
}
