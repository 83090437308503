.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 1%;
  justify-content: center;
  margin-left: 1%;
  width: 78%;
  border-right: 2px solid black;
  margin-right: 1%;
}

.cardimg {
  object-fit: cover;
  object-position: 0 2%;
}

.hungrybox {
  height: 540px;
  width: 960px;
  background-color: yellow;
  padding: 2%;
}

.card {
  color: black;
  border: 1px solid #ccc;
  box-shadow: 5px 5px lightgray;
  cursor: pointer;
  margin: 2px;
  width: 315px;
  img {
    height: 170px;
    width: 315px;
  }
}

.date {
  font-weight: normal;
  font-size: 1em;
}

.games {
  font-weight: bold;
  font-size: 1.4em;
}

.gamingList {
  h2 {
    background-color: red;
    color: white;
    text-align: center;
    padding-left: 5px;
  }
  border-radius: 10px;
  border: 4px solid red;
  margin-bottom: 30px;
}

.homeContainer {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}

.techList {
  h2 {
    background-color: black;
    color: white;
    text-align: center;
    padding-left: 5px;
  }
  border-radius: 10px;
  border: 4px solid black;
  margin-bottom: 30px;
}

.releases {
  list-style: none;
  margin: 0;
  font-size: 1.5em;
}

.releasesList {
  h2 {
    color: #ff0000;
    text-align: center;
    padding-left: 5%;
  }
  ul {
    padding: 5%;
  }
  border-radius: 10px;
  margin-bottom: 30px;
}

.sidebar {
  display: block;
  width: 18%;
}

.slide {
  flex: 1 0 300px;
  box-shadow: 0 1px 1rem -4px #000;
  overflow: hidden;
  border-radius: 6px;
  margin: 1.6rem;
  cursor: pointer;
  transition: all 250ms ease-in-out;
}

.slideContainer {
  display: flex;
  overflow-x: auto;
  margin-left: 1%;
  width: 80%;
  margin: auto;
  scroll-behavior: smooth;
}

.sidebarHeader {
  font-size: 2.5em;
  margin: auto;
}

#title {
  padding: 15px;
  text-align: center;
  font-size: 1.6rem;
}

.topCompanies {
  margin-top: 0;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  font-size: 2em;
  justify-content: left;
  list-style: decimal;
}

@media screen and (max-width: 855px) {
  .card {
    width: 240px;
    img {
      width: 240px;
    }
  }
  .cardsContainer {
    margin-left: 1%;
    border-right: none;
    width: 98%;
    margin-bottom: 5%;
  }
  .homeContainer {
    display: block;
  }
  .releasesList {
    margin-left: 1%;
    border: 4px solid black;
  }
  .sidebar {
    width: 90%;
    margin-left: 5%;
    li {
      margin-left: 3%;
    }
  }
}

@media screen and (max-width: 500px) {
  .card {
    width: 150px;
    font-size: 19px;
    img {
      width: 150px;
      object-position: 50% 50%;
    }
  }
  #title {
    height: 45px;
  }
  .releasesList {
    margin-left: 1%;
    border: 4px solid black;
  }
}
